@keyframes blick {
  0% {
    transform: translate(-100%, -25%) rotate(10deg);
  }
  20% {
    transform: translate(100%, -25%) rotate(10deg);
  }
  21% {
    transform: translate(100%, -25%) rotate(0deg);
  }
  100% {
    transform: translate(100%, -25%) rotate(0deg);
  }
}

.flair {
  content: '';
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  filter: blur;
  background: linear-gradient(
    to left,
    transparent 0%,
    #1fbccb 40%,
    #1fbccb 60%,
    transparent 100%
  );
  transform: translate(-100%, -25%) rotate(45deg);
  animation: blick 2s linear infinite;
}
